import React, { HTMLAttributes } from 'react'
import tw, { styled } from 'twin.macro'
import { up } from 'styled-breakpoints'

const StyledContainer = styled.section<ContainerProps>`
  max-width: 100%;

  ${tw`px-4 mx-auto w-full`}

  ${up('sm')} {
    max-width: 90%;
  }

  ${up('lg')} {
    max-width: 1024px;
    ${tw`px-10`}
  }

  ${up('xl')} {
    max-width: 1200px;
  }
`

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

export const Container = ({ children, ...otherProps }: ContainerProps) => {
  return <StyledContainer {...otherProps}>{children}</StyledContainer>
}
