import React, { useEffect, useRef, useState } from 'react'
import { Logo } from './Logo'
import { Container } from '../UI/Container'
import { cx } from '@emotion/css'
import { useToggle } from '../../lib/hooks/useToggle'
import { useOnClickOutside } from '../../lib/hooks/useOnClickOutside'
import { RiCloseFill } from 'react-icons/ri'
import { CgMenuRight } from 'react-icons/cg'
// import { ContactUsModal } from '../Modal/ContactUsModal'
// import { useToggle } from '../../lib/hooks/useToggle'
import { Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { styled } from 'twin.macro'
import { Link } from "gatsby"

const NavItems = () => {
  return (
    <>
      <li>
        <Link to="/#ourCompanySection" tw="font-semibold">
          Our company
        </Link>
      </li>
      <li>
        <Link to="/#aboutUsSection" tw="font-semibold">
          About us
        </Link>
      </li>
      <li>
        <Link to="/#articlesSection" tw="font-semibold">
          Insights
        </Link>
      </li>
      <li>
        <Link to="/#ourTeamSection" tw="font-semibold">
          Our team
        </Link>
      </li>
      {/* <Button
        href="https://us6.list-manage.com/contact-form?u=0f0558875f32e34fb088adb0d&form_id=3e937f54a51fd7a3466f44a43ad1f67d"
        targetBlank
      >
        Contact us
      </Button> */}
    </>
  )
}

const StyledTransitions = styled.div`
  .enter {
    -webkit-transition: opacity 450ms ease-in-out, max-height 300ms ease-out;
    transition: opacity 450ms ease-in-out, max-height 300ms ease-out;
  }

  .enterFrom {
    max-height: 0;
  }

  .enterTo {
    max-height: 500px;
  }
`

export const Navbar = () => {
  const [isScrollAtTop, setIsScrollAtTop] = useState(false) //Should be "false", set to true so initially loads showing.

  useEffect(() => {
    const trackScroll = () => {
      const scrolled = document.scrollingElement?.scrollTop
      if (!scrolled) return

      if (scrolled >= 120 && isScrollAtTop === true) {
        setIsScrollAtTop(false)
      } else if (scrolled < 120 && isScrollAtTop === false) {
        setIsScrollAtTop(false) //Should be "true", set to false so it shows when scrolling.
      }
    }

    document.addEventListener('scroll', trackScroll)
    return () => {
      document.removeEventListener('scroll', trackScroll)
    }
  }, [isScrollAtTop])

  // const [
  //   contactUsModalIsOpen,
  //   { setTrue: openContactUsModal, setFalse: closeContactUsModal },
  // ] = useToggle()

  const dropdownRef = useRef<HTMLDivElement>(null)

  const [
    dropdownNavIsOpen,
    { setFalse: closeDropdownNav, toggle: toggleDropdownNav },
  ] = useToggle()

  useOnClickOutside(dropdownRef, closeDropdownNav)

  return (
    <>
      <nav
        ref={dropdownRef}
        tw="py-2 fixed top-0 w-full z-20 transition duration-200"
        className={cx({
          'bg-white text-black shadow-sm': !isScrollAtTop,
          'bg-transparent text-white': isScrollAtTop,
        })}
      >
        <Container tw="flex items-center justify-between">
          <Link to="/">
            <Logo />
          </Link>
          <ul tw="hidden lg:flex items-center space-x-2 sm:space-x-6">
            <NavItems />
          </ul>

          {/* Mobile Dialog Button*/}
          <button
            type="button"
            onClick={toggleDropdownNav}
            tw="ml-auto lg:ml-10 lg:hidden inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          >
            <span className="sr-only">Open main menu</span>
            {dropdownNavIsOpen ? (
              <RiCloseFill className="block h-6 w-6" aria-hidden="true" />
            ) : (
              <CgMenuRight className="block h-6 w-6" aria-hidden="true" />
            )}
          </button>
        </Container>

        {/* Mobile Navigation */}
        <StyledTransitions tw="z-10">
          <Transition
            as={Fragment}
            show={dropdownNavIsOpen}
            enter="enter ease-out"
            enterFrom="enterFrom transform opacity-0"
            enterTo="enterTo transform opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <section tw="relative">
              <div
                tw="lg:hidden top-0 absolute left-0 right-0 -mb-2 flex flex-col z-10"
                className={cx({
                  'bg-white text-black shadow-sm': !isScrollAtTop,
                  'bg-transparent text-white': isScrollAtTop,
                })}
              >
                <Container tw="w-auto pt-8 pb-8 list-none space-y-4 text-center justify-center items-center">
                  <NavItems />
                </Container>
              </div>
            </section>
          </Transition>
        </StyledTransitions>
      </nav>

      {/* <ContactUsModal
        isOpen={contactUsModalIsOpen}
        close={closeContactUsModal}
      /> */}
    </>
  )
}
