import React from 'react'
import { Container } from '../UI/Container'
import { FaLinkedinIn } from 'react-icons/fa'
import { Link } from 'gatsby'

const socialNetworks = [
  {
    icon: FaLinkedinIn,
    url: 'https://www.linkedin.com/company/commercium-bank/',
  },
]

export const Footer = () => {
  return (
    <div tw="bg-blue-900 text-blueGray-400 py-10">
      <Container tw="flex flex-col">
        <div tw="flex flex-col md:grid md:grid-cols-3 items-center md:items-end justify-between">
          <div>
            <Link to="/privacy-policy" tw="hover:text-white">
              Privacy policy
            </Link>
          </div>

          <div tw="hidden md:block text-center mt-5">
            <p>© 2024 Commercium Financial, Inc.</p>
            <p>All Rights Reserved</p>
          </div>

          <div tw="space-y-1 mt-4 md:text-right">
            <div tw="flex pb-2 space-x-4 justify-center md:justify-end">
              {socialNetworks.map(({ url, icon: Icon }) => (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={url}
                  tw="hover:text-white"
                >
                  <Icon tw="text-4xl" />
                </a>
              ))}
            </div>

            <div>
              <a
                href="mailto:info@commercium.com"
                target="_blank"
                rel="noopener noreferrer"
                tw="hover:text-white"
              >
                info@commercium.com
              </a>
            </div>
          </div>

          <div tw="block md:hidden text-center mt-5">
            <p>© 2024 Commercium Financial, Inc.</p>
            <p>All Rights Reserved</p>
          </div>
        </div>
      </Container>

      <Container tw="mt-10">
        <p tw="text-justify text-sm">
          Deposits with Commercium and investment products and services of 
          Commercium are not FDIC insured. Your funds are subject to loss of 
          value, including the amount deposit and the principal amount invested.
        </p>
        <br />
        <p tw="text-justify text-sm">
          <strong>About Commercium:</strong> Commercium Financial, Inc. is a
          Wyoming SPDI Bank formed to serve as a custodian of digital assets and
          securities that can bridge to the US federal reserve system.
          Commercium is required to comply with Wyoming law pertaining to
          digital assets and special purpose depository institutions as amended.
          Commercium is required to always maintain 100% of its dollar deposits
          in reserve. Neither this site nor any press releases or statement made
          by an officer or director of Commercium contained herein, constitute
          an offer to sell or a solicitation of an offer to purchase any
          securities, although they may contain “forward-looking statements”
          within the meaning of the safe harbor provisions of the U.S. Private
          Securities Litigation Reform Act of 1995. Such forward-looking
          statements are based on current beliefs, assumptions, expectations,
          estimates, regulatory frameworks, and business projections and should
          not be relied upon, and/or may change without notice, as actual
          results may differ materially from these expectations due to certain
          risks, uncertainties, and other important factors. You are cautioned
          future circumstances, events, or results may differ materially from
          those projected in the forward-looking statements.
        </p>
      </Container>
    </div>
  )
}
