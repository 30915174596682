import React from 'react'

export const Logo = ({ ...props }) => {
  return (
    <div {...props}>
      <img
        src="/images/logo/commercium-main.svg"
        alt="Logo"
        tw="inline-block w-14"
      />
      <span tw="inline-block ml-1 text-3xl align-middle text-commerciumBlueGray">Commercium</span>
    </div>
  )
}
